import React from 'react'
import logo from "../../../assets/TClogo.png";
import { Button, Grid, Modal, TextField, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import FooterBack from "../../../assets/FooterBack.svg";
import LogoFooter from "../../../assets/LogoFooter.svg";
import location2 from "../../../assets/location2.svg";
import Phone from "../../../assets/Phone.svg";
import Email from "../../../assets/Email.svg";
import GooglePlay from "../../../assets/GooglePlay.svg";
import AppStore from "../../../assets/AppStore.svg";
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const isXs = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();

    const navigatePrivacy = () => {
        navigate('/privacy-policy')
    }
    const navigateTerms = () => {
        navigate('/terms-conditions')
    }
    return (
        <>
            {/* Footer */}
            <Grid container sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: isXs ? '' : 'center', backgroundColor: isXs ? "" : "#20140E", paddingBottom: isXs ? "" : "15%" }}>
                <img src={FooterBack} alt="Frame24"
                    style={{
                        position: "absolute",
                        width: "100%",
                        height: "auto",
                        zIndex: -2,
                        display: isXs ? '' : 'none'
                    }} />
                <Grid item xs={10} sm={3} md={3} lg={3} xl={3} xxl={3} spacing={3} sx={{ marginTop: '5%' }}>
                    <Box style={{ marginTop: "7%", display: 'flex' }}>
                        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Box sx={{ textAlign: 'center' }}>
                                <img src={LogoFooter} alt="LogoFooter" style={{ width: '70%' }} />
                            </Box>
                            <Box
                                style={{
                                    width: isXs ? "" : "95%",
                                    color: "white",
                                    fontSize: isXs ? "1vw" : '3.8vw',
                                    textAlign: 'center',
                                    margin: '0 auto',
                                    textAlign: 'center',
                                    marginTop: isXs ? "" : "2%"
                                }}
                            >
                                Empower your learning journey with Kanha Home Tuitions—where
                                potential meets possibility right at your doorstep!
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={10} sm={3} md={3} lg={3} xl={3} xxl={3} spacing={3} sx={{ marginTop: '8%' }}>
                    <Box style={{ marginRight: "10%", marginTop: "10%", fontSize: "1vw", minWidth: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ width: '80%', display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ width: '60%' }}>
                                <button className="d-flex align-items-center" style={{ backgroundColor: 'transparent', border: 'none', width: '100%', justifyContent: isXs ? '' : 'center' }}>
                                    <Box
                                        style={{
                                            color: "white",
                                            marginLeft: "5%",
                                            marginTop: "5%",
                                            fontSize: isXs ? '' : '3.8vw',
                                        }}
                                    >
                                        Home
                                    </Box>
                                </button>
                                <button onClick={navigatePrivacy} className="d-flex align-items-center" style={{ backgroundColor: 'transparent', border: 'none', width: '100%', justifyContent: isXs ? '' : 'center' }}>
                                    <Box
                                        style={{
                                            color: "white",
                                            marginLeft: "5%",
                                            marginTop: "5%",
                                            fontSize: isXs ? '' : '3.8vw',
                                        }}
                                    >
                                        Privacy Policy
                                    </Box>
                                </button>
                                <button onClick={navigateTerms} className="d-flex align-items-center" style={{ backgroundColor: 'transparent', border: 'none', width: '100%', justifyContent: isXs ? '' : 'center' }}>
                                    <Box
                                        style={{
                                            color: "white",
                                            marginLeft: "5%",
                                            marginTop: "5%",
                                            fontSize: isXs ? '' : '3.8vw',
                                        }}
                                    >
                                        Terms & Conditions
                                    </Box>
                                </button>
                            </Box>
                        </Box>

                    </Box>
                </Grid>
                <Grid item xs={10} sm={3} md={3} lg={3} xl={3} xxl={3} spacing={3} sx={{ marginTop: '8%' }}>
                    <Box style={{ marginRight: "10%", marginTop: "10%", fontSize: "1vw", minWidth: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Box>
                            <Box className="d-flex align-items-center " >
                                <img src={location2} alt="location2" width="8%" />
                                <Box style={{ color: "white", marginLeft: "5%", minWidth: isXs ? '95%' : '95%', fontSize: isXs ? '' : '3.8vw' }}>
                                    Plot No. 45, Vaishali Nagar, Jaipur
                                </Box>
                            </Box>
                            <Box className="d-flex align-items-center">
                                <img src={Phone} alt="Phone" width="7%" />
                                <Box
                                    style={{
                                        color: "white",
                                        marginLeft: "5%",
                                        marginTop: "5%",
                                        fontSize: isXs ? '' : '3.8vw'
                                    }}
                                >
                                    +91-6376400386
                                </Box>
                            </Box>
                            <Box className="d-flex align-items-center">
                                <img src={Email} alt="Email" width="7%" />
                                <Box
                                    style={{
                                        color: "white",
                                        marginLeft: "5%",
                                        marginTop: "5%",
                                        fontSize: isXs ? '' : '3.8vw'
                                    }}
                                >
                                    kanhahome04@gmail.com
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </Grid>
                <Grid item xs={10} sm={3} md={3} lg={3} xl={3} xxl={3} spacing={3} sx={{ marginTop: '6%' }}>
                    <Box style={{ marginRight: "10%", marginTop: isXs ? "10%" : "18%", minWidth: '100%', textAlign: 'center' }}>
                        <a href="">
                            <img src={GooglePlay} alt="GooglePlay" width="70%" />
                        </a>
                        <a href="">
                            <img
                                src={AppStore}
                                alt="AppStore"
                                width="70%"
                                style={{ marginTop: "10%" }}
                            />
                        </a>
                    </Box>
                </Grid>
            </Grid>


            {/* footer */}
            <Grid container className="py-3 px-5" sx={{ marginTop: isXs ? "7%" : "", backgroundColor: "#A6653F", fontSize: isXs ? "1vw" : "3vw", color: "white" }}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} sx={{ margin: isXs ? '' : "0 auto" }}>
                    <Box style={{ textAlign: isXs ? 'start' : "center" }} >
                        &copy;2024 Kanha Home Tutions. All Right Reserved{" "}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} sx={{ margin: isXs ? '' : "0 auto", marginTop: isXs ? "" : "2%" }}>
                    <Box style={{ textAlign: isXs ? 'end' : "center" }}>Powered by Infomagine</Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Footer
