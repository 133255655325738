import React, { useState } from 'react'
import img from '../../../assets/img.jpg';
import loginstudent from '../../../assets/loginstudent.svg';
import line from '../../../assets/line.svg';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { signIn, storeUpdateNumber } from '../../features/teacher/teacherAuthSlice';
import { useNavigate } from 'react-router-dom';
import { Backdrop, Button, CircularProgress } from '@mui/material';

const LoginTeacher = () => {

    const [mobile, setMobile] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const isValidIndianMobileNumber = (mobile) => {
        const indianMobileNumberPattern = /^[6-9]\d{9}$/;
        return indianMobileNumberPattern.test(mobile);
    };
    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        // Restrict input to 10 digits
        if (value.length <= 10) {
            setMobile(value.replace(/\D/, '')); // Remove non-digit characters
        }
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            document.getElementById('submitBtn2').click();
        }
    };
    const submitHandler = (e) => {
        e.preventDefault();

        if (mobile.trim() === '') {
            toast.error('Mobile number is required.');
            return;
        }
        if (mobile.length !== 10) {
            toast.error('Mobile number must be exactly 10 digits.');
            return;
        }
        const isValid = isValidIndianMobileNumber(mobile);
        if (!isValid) {
            toast.error('Please enter a valid Indian mobile number.');
            return;
        }

        dispatch(signIn({ mobile: mobile, roleId: 3 })).then((response) => {
            if (response.payload.status == 200) {
                toast.success(response.payload.message);
                if (response?.payload?.data) {
                    dispatch(storeUpdateNumber(mobile))
                    navigate('/tutor-verify-otp');
                } else {
                    navigate('/signin-teacher')
                }
            } else {
                toast.error(response.payload.message);
            }
        }).catch((error) => {
            console.log(error, 'somthing went wrong');
        })

    }

    const handleClose = () => {
        window.location.href = 'https://kanhahometutions.com';
    }

    const loading = useSelector((state) => state?.teacherSignUp?.loading);


    return (
        <div style={{ backgroundImage: `url(${img})`, backgroundSize: 'cover', height: '100vh' }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className='d-flex flex-row justify-content-center align-items-center'>
                <div className='container border-0'>
                    <div className='row justify-content-center'>
                        <div style={{ width: '400px', height: '560px' }}>

                            <div style={{ marginTop: '20%', backgroundColor: '#ffffff', position: 'relative' }}>
                                <Button style={{ position: 'absolute', right: '-2%', backgroundColor: 'white', borderRadius: '100%', minWidth: '2rem', color: 'black', marginTop: '-10px' }} onClick={() => handleClose()}>X</Button>

                                <div style={{ height: '30rem' }}>

                                    <div>
                                        <img src={loginstudent} alt='vector' className='img-fluid' style={{ height: '100%', width: '100%' }} />

                                    </div>

                                    <div className='d-flex flex-row justify-content-center p-2'>
                                        <div>
                                            <h3 className="d-flex justify-content-center mt-1" style={{ fontSize: '30px', fontWeight: 'bold' }}>Login as Teacher</h3>
                                            <img src={line} className='mb-4' />
                                        </div>
                                    </div>
                                    <form onSubmit={submitHandler}>

                                        <div className="d-flex justify-content-center fw-bold py-">
                                            <Box
                                                component="form"
                                                sx={{ borderColor: 'white', borderRadius: 0 }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <TextField
                                                    label="Phone Number"
                                                    onChange={handlePhoneNumberChange}
                                                    size='small'
                                                    placeholder='Enter your Phone Number'
                                                    inputProps={{ style: { width: '270px', borderRadius: '0' } }}
                                                    onKeyPress={handleKeyPress} // Handle Enter key press

                                                    color='grey'
                                                    focused
                                                    value={mobile} />
                                            </Box>
                                        </div>
                                        <div className="py-4 d-flex justify-content-center px-5" style={{ width: '100%' }}>
                                            <button id="submitBtn2" type="submit" className="btn btn-primary py-2 w-100 border-0" style={{ fontSize: '14px', backgroundColor: '#FFDB7E', color: '#866D2C', fontWeight: '600', borderRadius: '0', borderColor: '#CB8B65' }}>Continue</button>
                                        </div>
                                    </form>

                                    <div style={{ textAlign: 'center' }}>
                                        Don't Have an Account ? <span style={{ fontWeight: "bold" }}> <a style={{ color: "#CB8B65", textDecoration: "none" }} href="/signup-teacher">Sign Up</a> </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginTeacher
