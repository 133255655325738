import React, { memo, useEffect, useMemo, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import search from "../../../assets/search.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { userLists ,userListsV2,userAccountCloseOpen} from "../../features/admin/accountPostSlice";
import {
  Backdrop, CircularProgress, Switch, styled, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Link } from "react-router-dom";
import StudentOverviewPopup from "../../ModalPopups/StudentOverviewPopup";
import { TablePagination, IconButton } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import your custom icon
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // Import your custom icon



const columns = [
  { id: "id", label: "ID" },
  { id: "name", label: "Name" },
  { id: "mobile", label: "Mobile" },
  { id: "email", label: "Email" },
  // { id: "city_name", label: "City" },
  // { id: "state_name", label: "State" },
  { id: "active/inactive", label: "Status" },

  // { id: "action", label: "Action" },
];

 const StudentList=()=> {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [swtichToggle, setSwtichToggle] = React.useState(true);
  const [open, setOpen] = useState(false);
  const [dataColumn2, setDataColumn2] = useState([]);
  const [dataRow2, setDataRow2] = useState([]);
  const [open2, setOpen2] = useState(false);
  const [searchTermQuery, setSearchTermQuery] = React.useState("");

  
  const [currentId, setCurrentId] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [resp,setResp]=useState();

  const { userListData, loading } = useSelector((state) => state?.accountPost);
 // Memoize a derived value

const metaData = useMemo(()=>({... userListData?.meta}),[userListData])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const handleConfirm = () => {
    // Handle the confirmation action here
    const paylaod = {
      userId: currentId,
      reasonId: 0,
      description: "By Admin",
      accountStatus: !currentStatus
    }
    dispatch(userAccountCloseOpen(paylaod)).then((resp) => {

      setResp(resp)
      // window.location.reload();

    });
    // setSwtichToggle(!swtichToggle)

    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {

    setRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchBtn();  // Trigger the search when Enter is pressed
    }
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
   
    // setRowsPerPage(+rowsPerPage);
    // setCurrentPage(0);
  };
  const handleSearchBtn=()=>{
    
      setSearchTermQuery(searchTerm);
  
      setRowsPerPage(+rowsPerPage);
      setCurrentPage(0);
   
  }

  const studentList = () => {
    const d = new Date();
    let text = d.toLocaleDateString();
    let token = `vkSoft1234${text}`
    
    const paylaod={ roleId: 2,
       token: token,
        page: currentPage? currentPage:1,
         pageCount: rowsPerPage
         }
         if (searchTermQuery.length > 0) {
          paylaod.search = searchTermQuery
          paylaod.page = 1
        }
    dispatch(userListsV2(paylaod));
  }

  const handleClickOpen2 = (popupData) => {

    const dataColumns = ["Date", "Board", "Class", "Subject", "Preferred Class", "Max. Fee", "Availability", "Plan to Start", "Study Mode", "Gender Preference", "Address 1", "Address 2", "State", "City", "Pincode","Status"]
    setDataRow2(popupData)
    setDataColumn2(dataColumns);
    // setTitle(titlepopup);
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleOverviewBtn = (value,popupData) => {
    let subName = value.split(" ").map(name => {
      return name.charAt(0).toUpperCase() + name.slice(1);
    });

    return (<a href="javascript:void(0)"
      onClick={() => handleClickOpen2(popupData)}
    >{subName.join(" ")}</a>)
  }

  useEffect(() => {
    studentList();
  }, [resp,currentPage, rowsPerPage,searchTermQuery,dispatch])



  const rows = userListData?.data?.map((student) => {
    const hasStudentPost =
      student?.student_post && student.student_post.length > 0;
    return {
      id: "#STU" + student?.userId,
      name:
        student?.firstName && student?.lastName
          ? `${student?.firstName} ${student?.lastName}`
          :"-",
      mobile: student?.mobile ?? "-",
      email: student?.email ?? "-",
      overView:student?.studentPost,
      acccountStatus:student?.acccountStatus,
    };
  });

  const filteredRows = rows?.filter((row) => {
    return (
      row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.mobile.includes(searchTerm) ||
      row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.city_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.state_name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  
  const handleSwitchChange = (id, currentStat) => {
    setCurrentId(id)
    setCurrentStatus(currentStat)
    setOpen(true)
  };
  const handleActionBtn = (row) => {
    let rowId = row?.id.replace(/#STU/g, "");
    return (
      <CustomSwitch
        checked={row?.acccountStatus?true:false}
        //row.enabled}
        onChange={() => handleSwitchChange(rowId,row?.acccountStatus)}
        color="warning"
      />
    )
  }
  const CustomSwitch = styled(Switch)(({ theme }) => ({
    '&.Mui-disabled': {
      color: theme.palette.action.disabled,
      '& .MuiSwitch-thumb': {
        color: theme.palette.action.disabled,
      },
      '& .MuiSwitch-track': {
        backgroundColor: theme.palette.action.disabledBackground,
      },
    },
  }));


  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        height: "100vh",
        borderRadius: "10px",
      }}
    >
      {/*  */}


      <StudentOverviewPopup
        open={open2}
        onClose={handleClose2}
        data={dataRow2}
        title={"Student Overview"}
        DataColumn={dataColumn2}
      />
      {/*  */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            position: 'absolute',
            top: 50, // Align the dialog to the top
            m: 0 // Remove the default margin
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Change"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to make this change?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ background: "#f3b054" }}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} style={{ background: "#f3b054" }} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/*  */}
      <main
        style={{
          marginLeft: "0rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "2rem",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "4rem",
            backgroundColor: "#ffffff",

            borderRadius: "10px",
            marginTop: "0rem",
            display: "flex",
            alignItems: "center",
            padding: "1rem",
            justifyContent: "space-between",
            boxShadow: "0px 4px 4px 2px rgb(231, 231, 231)",
          }}
        >
          <span
            style={{ color: "#CB8B65", fontSize: "1.2rem", fontWeight: "600" }}
          >
            All Students
          </span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <InputGroup>
              <Form.Control
                placeholder="Search...."
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyDown={(event) => handleKeyDown(event)}  // Detect Enter key press

              />
              <Button 
              onClick={()=>handleSearchBtn()}

              style={{ backgroundColor: "#CB8B65", border: 0 }}
              >
                <img src={search} alt="" />
              </Button>
            </InputGroup>
          </div>
        </div>
        <div style={{ marginLeft: "2rem" }}>
          <div>
            <p style={{ fontSize: "1.5rem" }}>All Student List</p>
          </div>
          <Paper
            sx={{ width: "95%", overflow: "hidden", scrollBehavior: "smooth" }}
          >
            <TableContainer sx={{ maxHeight: 360 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: "#CB8B65",
                          fontWeight: "500",
                          color: "#ffffff",
                          fontSize: "1.1rem",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                {loading ? (
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                ) : (
                  <TableBody>
                    {
                      rows?.map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {columns.map((column) => {
                              const value = column.id == "active/inactive" ? handleActionBtn(row) : column.id == "name" ? handleOverviewBtn(row[column.id],row?.overView) : row[column.id];


                              return (
                                <TableCell key={column.id} >

                                  {
                                    value}
                                </TableCell>
                              );
                            })}

                          </TableRow>
                        );
                      })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={metaData?.total}
              rowsPerPage={rowsPerPage}
              page={!metaData?.total || metaData?.total <= 0 ? 0 : currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              // showFirstButton={true}
              // showLastButton={true}
              
            />
          </Paper>
        </div>
      </main>
    </div>
  );
}

export default  memo( StudentList )