import React, { useEffect, useState } from "react";
import img from "../../../assets/img.jpg";
import Signup1 from "../../../assets/Signup1.png";
import CurveLine from "../../../assets/CurveLine.png";
import wifi2 from "../../../assets/wifi2.png";
import imgs from "../../../assets/imgs.svg";
import LogoHeader from "../../../assets/LogoHeader.svg";
import book from "../../../assets/book.svg";
import teachersignupimg from "../../../assets/teachersignupimage.svg";
import bulb from "../../../assets/bulb.svg";
import line from "../../../assets/line.svg";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Autocomplete, Backdrop, Button, CircularProgress, Grid, MenuItem, useMediaQuery } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { citiesListing, getPinCodeStatus } from "../../features/teacher/teacherListingSlice";
import { signUp, storeUpdateNumber, } from "../../features/teacher/teacherAuthSlice";

const SignupTeacher = () => {
    const dispatch = useDispatch();
    const [mobile, setMobile] = useState('');
    const [isPincode, setIsPincode] = useState('');
    const [isPincodeChecked, setIsPincodeChecked] = useState(true);
    const [cityIdData, setCityIdData] = useState("");


    const navigate = useNavigate()
    const schema = yup.object().shape({
        email: yup.string().email().required(),
        firstName: yup.string().required("firstName is a req. field"),
        lastName: yup.string().required(),
        cityId: yup.object().required(),
        // mobile: yup.number().required(),
        mobile: yup.string().matches(/^[0-9]{10}$/, {
            message: 'Mobile number must be exactly 10 digits',
            excludeEmptyString: true  // Exclude empty string from validation
        }).required(),
        // mobile: yup.string().test('len', 'Mobile number must be exactly 10 digits', (value) => {
        //     if (!value) return false; // Handle empty string case if required
        //     const strippedValue = value.replace(/\D/g, ''); // Remove non-digit characters
        //     return strippedValue.length === 10; // Validate length
        // }).required('Mobile number is required'),
        // mobile: yup.string()
        // .transform(value => value.replace(/\D/g, '')) // Transform to remove non-digits
        // .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits')
        // .required('Mobile number is required'),
        genderId: yup.string().required(),
        pinCode: yup.string().matches(/^[0-9]{6}$/, {
            message: 'Mobile number must be exactly 6 digits',
            excludeEmptyString: true  // Exclude empty string from validation
        }).required(),
        addressLineOne: yup.string().required(),
        addressLineTwo: yup.string().required(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
        },
    });

    const handleMobileNumberChange = (e) => {
        const value = e.target.value;
        // Restrict input to 10 digits
        if (value.length <= 10) {
            setMobile(value.replace(/\D/, '')); // Remove non-digit characters
        }
    };
    const handlePincode = async (e) => {
        const re = /^[0-9\b]+$/; //rules
        if (e.target.value === "" || re.test(e.target.value)) {
            setIsPincode(e.target.value);

        }

    }

    const onSubmitHandler = async (data) => {
        if(!isPincodeChecked){
          return  toast.error("Please fill right Pincode !!")
        }
    // return;
        dispatch(signUp({
            firstName: data.firstName, lastName: data.lastName, email: data.email, mobile: data.mobile,
            genderId: data.genderId, stateId: data.cityId.stateId, cityId: data.cityId.id, countryId: 101,
            pinCode:data.pinCode,addressLineOne:data.addressLineOne,addressLineTwo:data.addressLineTwo,
            roleId: 3, created_by: 3
        })).then((response) => {
            if (response?.payload?.status == 200) {
                toast.success(response?.payload?.message);
                dispatch(storeUpdateNumber(data.mobile))
                reset();
                navigate('/tutor-verify-otp')
            } else {
                toast.error(response?.payload?.message);
            }
        }).catch((error) => {
            console.log(error, 'teacher-sigup-page-error');
        });
    };

    const findCities = async () => {
        dispatch(citiesListing({ stateId: 0 }));
    };

    useEffect(() => {
        findCities();
    }, []);

    const cities = useSelector((state) => state?.teacherListing?.citiesListing);
    // const loading = useSelector((state) => state?.teacherListing?.loading);
    console.log("citcityIdDatacityIdDatayId", cityIdData)

    // console.log(cities, "cities");

    const placeholderOption = { cityName: 'Select a city', stateName: '', isPlaceholder: true };
    // const citiesWithPlaceholder = [...cities];
    const loading = useSelector((state) => state?.teacherSignUp?.loading);


    const isXs = useMediaQuery("(min-width:600px)");

    const handleClose = () => {
        navigate('/signin-teacher')
    }

    const handlePinCodeCheck = async (e) => {
        setIsPincodeChecked(false)
        try {
            const response = await dispatch(getPinCodeStatus(e.target.value))

            if (response?.payload[0]?.Status == "Success") {
                const res1 = response?.payload[0]?.PostOffice.some((item) => (cityIdData.stateName.toLowerCase().toString() == item.State.toLowerCase().toString()));
                const res2 = response?.payload[0]?.PostOffice.some((item) => (cityIdData.cityName.toLowerCase().toString() == item.District.toLowerCase().toString()));
                const res3 = response?.payload[0]?.PostOffice.some((item) => (cityIdData.cityName.toLowerCase().toString() == item.Division.toLowerCase().toString()));
                const res4 = response?.payload[0]?.PostOffice.some((item) => (cityIdData.cityName.toLowerCase().toString() == item.Region.toLowerCase().toString()));
                const res5 = response?.payload[0]?.PostOffice.some((item) => (cityIdData.cityName.toLowerCase().toString() == item.Block.toLowerCase().toString()));
                const res6 = response?.payload[0]?.PostOffice.some((item) => (item.Name.toLowerCase().toString().includes(cityIdData.cityName.toLowerCase().toString())));
                // console.log({"res1":res1,"res2":res2,"res3":res3,"res4":res4,"res5":res5,"res6":res6})

                const res = res1 && res2 || res3 || res4 || res5 || res6

                if (res == false) {
                    setIsPincodeChecked(false)
                    toast.error("Please fill right PinCode !")

                } else if (res == true) {
                    setIsPincodeChecked(true);
                    toast.success("Pincode is right !")
                }

            } else {
                toast.error("No Pincode available !")
                setIsPincodeChecked(false)

            }

        } catch (error) {
            console.log("bk", error)
        }

    }

    return (
        <Box
            style={{
                backgroundImage: `url(${img})`,
                backgroundSize: "cover",
                height: "100vh",
            }}
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box className="d-flex flex-row justify-content-center align-items-center">
                <Box className="container border-0">
                    <Box className="row justify-content-center">
                        <Box style={{ width: "900px", height: "580px" }}>
                        <Button sx={{display: isXs ? "none" : ""}} style={{ zIndex: '1', position: 'absolute', right: '6px', backgroundColor: 'white', borderRadius: '100%', minWidth: '2rem', color: 'black', marginTop: '9px' }} onClick={handleClose}>X</Button>
                                        
                            <Box style={{ marginTop: "6%", backgroundColor: "#ffffff" }}>
                                <Box className="row">
                                    <Box sx={{backgroundColor: '#CB8B65'}}
                                        className="col-md-4 d-flex flex-column align-content-center justify-content-center"
                                        style={{ display: isXs ? "" : "none" }}
                                    >
                                      
                                        <Box sx={{display: isXs ? "" : "none"}} >
                                        <img src={teachersignupimg} alt="vector" width={300}
                                        
                                        className="img-fluid"
                                        style={{
                                            borderBottomLeftRadius: isXs ? "" : "50px",
                                            borderBottomRightRadius: isXs ? "" : "50px",
                                        }}/>

                                            {/* <img
                                                src={imgs}
                                                alt="vector"
                                                className="img-fluid"
                                                style={{
                                                    width: "100%",
                                                    borderBottomLeftRadius: isXs ? "" : "50px",
                                                    borderBottomRightRadius: isXs ? "" : "50px",
                                                }}
                                            /> */}
                                            
                                        </Box>
                                    </Box>
                                    <Box
                                        className="col-md-4"
                                        style={{ display: isXs ? "none" : "" }}
                                    >
                                        <Box
                                            style={{
                                                backgroundColor: "#CB8B65",
                                                height: "18rem",
                                                position: "relative",
                                                borderBottomLeftRadius: "20px",
                                                borderBottomRightRadius: "20px",
                                            }}
                                        >
                                            <Box className="d-flex justify-content-center">
                                                <img
                                                    src={Signup1}
                                                    style={{
                                                        position: "absolute",
                                                        bottom: 50,
                                                        top: 50,
                                                        width: "60%",
                                                    }}
                                                />
                                            </Box>
                                            <img
                                                src={wifi2}
                                                style={{ position: "absolute", bottom: 0, left: 5 }}
                                            />
                                            <img
                                                src={book}
                                                style={{ position: "absolute", top: 0, right: 5 }}
                                            />
                                            <img
                                                src={CurveLine}
                                                style={{ position: "absolute", top: 0, left: 0 }}
                                            />
                                            <img
                                                src={CurveLine}
                                                style={{
                                                    position: "absolute",
                                                    bottom: 0,
                                                    right: 0,
                                                    rotate: "180deg",
                                                }}
                                            />
                                        </Box>
                                    </Box>

                                    <Box  className="col-md-8" style={{ position: 'relative', }}>
                                        <Button sx={{display: isXs ? "" : "none"}} style={{ position: 'absolute', right: '-1%', backgroundColor: 'white', borderRadius: '100%', minWidth: '2rem', color: 'black', marginTop: '-12px' }} onClick={handleClose}>X</Button>

                                        
                                        
                                        <Box className="d-flex flex-row justify-content-between p-2">
                                            <Box>
                                                <h3 className="pt-3" style={{ fontWeight: "bold" }}>
                                                    Signup as a Teacher
                                                </h3>
                                                <img src={line} className="mb-4" />
                                            </Box>
                                            <img src={book} style={{ height: "12%", width: "12%" }} />
                                        </Box>
                                        <Box>
                                            <form onSubmit={handleSubmit(onSubmitHandler)}>
                                                <Box className="container">
                                                    <Box className="row" style={{ fontSize: "14px" }}>
                                                        <Grid container>
                                                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                                <Box
                                                                    component="form"
                                                                    sx={{
                                                                        "& > :not(style)": {
                                                                            width: isXs ? "95%" : "100%",
                                                                        },
                                                                    }}
                                                                    noValidate
                                                                    autoComplete="off"
                                                                >
                                                                    <TextField
                                                                        label="First Name"
                                                                        {...register("firstName")}
                                                                        name="firstName"
                                                                        placeholder="First Name"
                                                                        inputProps={{
                                                                            style: {
                                                                                height: "7px",
                                                                                fontSize: isXs ? "12px" : "16px",
                                                                            },
                                                                        }}
                                                                        InputLabelProps={{ sx: { color: "black" } }}
                                                                        color="grey"
                                                                        focused
                                                                    />
                                                                    {/* <TextField label="Phone Number"  size='small' placeholder='Enter your Phone Number' inputProps={{ style: { width: '270px', borderRadius:'0' } }} color='grey' focused /> */}

                                                                    <p style={{ fontSize: "12px", color: "red", marginRight: isXs ? '12%' : '' }}>
                                                                        {errors.firstName?.message}
                                                                    </p>
                                                                </Box>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={4}
                                                                lg={4}
                                                                xl={4}
                                                                sx={{
                                                                    textAlign: "end",
                                                                    marginTop: isXs ? "" : "5%",
                                                                }}
                                                            >
                                                                <Box
                                                                    component="form"
                                                                    sx={{
                                                                        "& > :not(style)": {
                                                                            width: isXs ? "95%" : "100%",
                                                                        },
                                                                    }}
                                                                    noValidate
                                                                    autoComplete="off"
                                                                >
                                                                    <TextField
                                                                        {...register("lastName")}
                                                                        name="lastName"
                                                                        label="Last Name"
                                                                        placeholder="Enter your Last Name"
                                                                        inputProps={{
                                                                            style: {
                                                                                height: "7px",
                                                                                fontSize: isXs ? "12px" : "16px",
                                                                            },
                                                                        }}
                                                                        InputLabelProps={{ sx: { color: "black" } }}
                                                                        color="grey"
                                                                        focused
                                                                    />
                                                                    <p style={{ fontSize: "12px", color: "red", marginRight: isXs ? '12%' : '' }}>
                                                                        {errors.lastName?.message}
                                                                    </p>
                                                                </Box>
                                                            </Grid>
                                                            {/*bkst  */}
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={4}
                                                                lg={4}
                                                                xl={4}
                                                                sx={{
                                                                    textAlign: "end",
                                                                    marginTop: isXs ? "" : "5%",
                                                                }}
                                                            >
                                                                <Box
                                                                    component="form"
                                                                    sx={{
                                                                        "& > :not(style)": {
                                                                            width: isXs ? "95%" : "100%",
                                                                        },
                                                                    }}
                                                                    noValidate
                                                                    autoComplete="off"
                                                                >
                                                                    <TextField
                                                                        {...register("genderId")}
                                                                        name="genderId"
                                                                        select
                                                                        label="Select Gender"
                                                                        placeholder="Select Your Gender"
                                                                        size="small"
                                                                        inputProps={{
                                                                            style: {
                                                                                fontSize: isXs ? "12px" : "16px",
                                                                            },
                                                                        }}
                                                                        InputLabelProps={{ sx: { color: "black" } }}
                                                                        color="grey"
                                                                        focused
                                                                    >
                                                                        <MenuItem value="0">Male</MenuItem>
                                                                        <MenuItem value="1">Female</MenuItem>
                                                                    </TextField>
                                                                    <p style={{ fontSize: "12px", color: "red", marginRight: isXs ? '12%' : '' }}>
                                                                        {errors?.genderId?.message}
                                                                    </p>
                                                                </Box>
                                                            </Grid>

                                                            {/* bkend */}



                                                        </Grid>



                                                        <Grid container sx={{ marginTop: "2%" }}>
                                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                <Box
                                                                    component="form"
                                                                    sx={{
                                                                        "& > :not(style)": {
                                                                            width: isXs ? "95%" : "100%",
                                                                        },
                                                                    }}
                                                                    noValidate
                                                                    autoComplete="off"
                                                                >
                                                                    <TextField
                                                                        {...register("email")}
                                                                        name="email"
                                                                        label="Email"
                                                                        placeholder="Enter your Email"
                                                                        inputProps={{
                                                                            style: {
                                                                                height: "7px",
                                                                                fontSize: isXs ? "12px" : "16px",
                                                                            },
                                                                        }}
                                                                        InputLabelProps={{ sx: { color: "black" } }}
                                                                        color="grey"
                                                                        focused
                                                                    />
                                                                    <p style={{ fontSize: "12px", color: "red", marginRight: isXs ? '12%' : '' }}>
                                                                        {errors.email?.message}
                                                                    </p>
                                                                </Box>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                                sx={{
                                                                    textAlign: "end",
                                                                    marginTop: isXs ? "" : "5%",
                                                                }}
                                                            >
                                                                <Box
                                                                    component="form"
                                                                    sx={{
                                                                        "& > :not(style)": {
                                                                            width: isXs ? "95%" : "100%",
                                                                        },
                                                                    }}
                                                                    noValidate
                                                                    autoComplete="off"
                                                                >
                                                                    <TextField
                                                                        {...register("mobile")}
                                                                        type="number"
                                                                        name="mobile"
                                                                        label="Phone Number"
                                                                        placeholder="Enter your Phone Number"
                                                                        inputProps={{
                                                                            style: {
                                                                                height: "7px",
                                                                                fontSize: isXs ? "12px" : "16px",
                                                                            },
                                                                        }}
                                                                        InputLabelProps={{ sx: { color: "black" } }}
                                                                        color="grey"
                                                                        focused
                                                                        value={mobile}
                                                                        onChange={handleMobileNumberChange}
                                                                    />
                                                                    <p style={{ fontSize: "12px", color: "red", marginRight: isXs ? '12%' : '' }}>
                                                                        {errors.mobile?.message}
                                                                    </p>
                                                                </Box>
                                                            </Grid>
                                                            {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ textAlign: 'end', marginTop: isXs ? '' : '5%' }}>
                                                                <Box
                                                                    component="form"
                                                                    sx={{
                                                                        '& > :not(style)': { width: isXs ? '95%' : '100%' },
                                                                    }}
                                                                    noValidate
                                                                    autoComplete="off"
                                                                >
                                                                    <TextField
                                                                        select
                                                                        label="Select Gender"
                                                                        placeholder="Select Your Gender"
                                                                        size='small'
                                                                        inputProps={{ style: { fontSize: isXs ? '12px' : '16px' } }}
                                                                        InputLabelProps={{ sx: { color: 'black' } }}
                                                                        color="grey"
                                                                        focused
                                                                    >
                                                                        <MenuItem value="male">Male</MenuItem>
                                                                        <MenuItem value="female">Female</MenuItem>
                                                                        <MenuItem value="other">Other</MenuItem>
                                                                    </TextField>
                                                                </Box>
                                                            </Grid> */}
                                                        </Grid>
                                                        {/* bkst2 */}
                                                        <Grid container sx={{ marginTop: "2%" }}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Box
                                                                    component="form"
                                                                    sx={{
                                                                        "& > :not(style)": {
                                                                            width: isXs ? "95%" : "100%",
                                                                        },
                                                                    }}
                                                                    noValidate
                                                                    autoComplete="off"
                                                                >
                                                                    <TextField
                                                                        {...register("addressLineOne")}
                                                                        name="addressLineOne"
                                                                        label="Address Line One"
                                                                        placeholder=" Enter Address Line One"
                                                                        sx={isXs ? { '& .MuiInputBase-root': { width: "105%" } }:{}}
                                                                        inputProps={{
                                                                            style: {
                                                                                width: "105%",
                                                                                height: "7px",
                                                                                fontSize: isXs ? "12px" : "16px",
                                                                            },
                                                                        }}
                                                                        InputLabelProps={{ sx: { color: "black" } }}
                                                                        color="grey"
                                                                        focused
                                                                    />
                                                                    <p style={{ fontSize: "12px", color: "red", marginRight: isXs ? '12%' : '' }}>
                                                                        {errors.addressLineOne?.message}
                                                                    </p>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginTop: "2%" }}>
                                                                <Box
                                                                    component="form"
                                                                    sx={{
                                                                        "& > :not(style)": {
                                                                            width: isXs ? "95%" : "100%",
                                                                        },
                                                                    }}
                                                                    noValidate
                                                                    autoComplete="off"
                                                                >
                                                                    <TextField
                                                                        {...register("addressLineTwo")}
                                                                        name="addressLineTwo"
                                                                        label="Address Line Two"
                                                                        placeholder=" Enter Address Line Two"
                                                                        sx={isXs ? { '& .MuiInputBase-root': { width: "105%" } }:{}}

                                                                        inputProps={{
                                                                            style: {
                                                                                height: "7px",
                                                                                fontSize: isXs ? "12px" : "16px",
                                                                            },
                                                                        }}
                                                                        InputLabelProps={{ sx: { color: "black" } }}
                                                                        color="grey"
                                                                        focused
                                                                    />
                                                                    <p style={{ fontSize: "12px", color: "red", marginRight: isXs ? '12%' : '' }}>
                                                                        {errors.addressLineTwo?.message}
                                                                    </p>
                                                                </Box>
                                                            </Grid>

                                                        </Grid>

                                                        {/* bkend2 */}

                                                        <Grid container sx={{ marginTop: "2%" }}>
                                                            {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                                                <Box
                                                                    component="form"
                                                                    sx={{
                                                                        '& > :not(style)': { width: isXs ? '95%' : '100%' },
                                                                    }}
                                                                    noValidate
                                                                    autoComplete="off"
                                                                >
                                                                    <TextField label="Email" placeholder='Enter your Email' inputProps={{ style: { height: '7px', fontSize: isXs ? '12px' : '16px' } }} InputLabelProps={{ sx: { color: 'black' } }} color='grey' focused />
                                                                </Box>
                                                            </Grid> */}
                                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                {/* <Box
                                                                    component="form"
                                                                    sx={{
                                                                        '& > :not(style)': { width: isXs ? '95%' : '100%' },
                                                                    }}
                                                                    noValidate
                                                                    autoComplete="off"
                                                                >
                                                                    <TextField label="Phone Number" placeholder='Enter your Phone Number' inputProps={{ style: { height: '7px', fontSize: isXs ? '12px' : '16px' } }} InputLabelProps={{ sx: { color: 'black' } }} color='grey' focused />
                                                                </Box> */}
                                                                {/* <Autocomplete
                                 {...register("cityId")}
                                 name="cityId"
                                  fullWidth
                                  id="free-solo-demo"
                                  freeSolo
                                //   onChange={handleChange}
                                  options={cities?.map(
                                    (option) =>
                                      option.cityName +
                                      " " +
                                      "(" +
                                      option.stateName +
                                      ")"
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Eg: Chandigarh (Punjab)"
                                    />
                                  )}
                                  disableClearable
                                /> */}

                                                                {/* <Controller
        name="cityId"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Autocomplete
            {...field}
            // options={cities.map((option) => `${option.cityName} (${option.stateName})`)}
            options={cities}
            getOptionLabel={(option) => `${option.cityName} (${option.stateName})`}
            renderOption={(props, option) => (
                <li {...props} style={{ display: option.isPlaceholder ? 'none' : 'block' }}>
                  {option.cityName} {option.stateName && `(${option.stateName})`}
                </li>
              )}
            renderInput={(params) => (
              <TextField {...params} label="Eg: Chandigarh (Punjab)" fullWidth />
            )}
            onChange={(_, value) => field.onChange(value)} // Update form value
            disableClearable
          />
        )}
      /> */}

                                                                <Controller
                                                                    name="cityId"
                                                                    control={control}
                                                                    defaultValue={null}
                                                                    render={({ field }) => (
                                                                        <Autocomplete
                                                                            {...field}
                                                                            options={cities}
                                                                            sx={{
                                                                                '& input': {
                                                                                    height: '0.500em',

                                                                                }
                                                                            }
                                                                            }
                                                                            getOptionLabel={(option) => `${option?.cityName} (${option?.stateName})`}
                                                                            renderOption={(props, option) => (
                                                                                <li {...props} style={{ display: option?.isPlaceholder ? 'none' : 'block' }}>
                                                                                    {option?.cityName} {option?.stateName && `(${option?.stateName})`}
                                                                                </li>
                                                                            )}
                                                                            renderInput={(params) => (
                                                                                <TextField {...params} label="Eg: Chandigarh (Punjab)" fullWidth
                                                                                />
                                                                            )}
                                                                            onChange={(_, value) => {
                                                                                setCityIdData(value);
                                                                                setIsPincode("");
                                                                                if (!value?.isPlaceholder) {
                                                                                    field?.onChange(value);
                                                                                }
                                                                            }}
                                                                            disableClearable
                                                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                                        />
                                                                    )}
                                                                />




                                                                <p style={{ fontSize: "12px", color: "red", marginRight: isXs ? '12%' : '' }}>
                                                                    {errors?.cityId?.message}
                                                                </p>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                                sx={{
                                                                    textAlign: "end",
                                                                    marginTop: isXs ? "" : "5%",
                                                                }}
                                                            >
                                                                <Box
                                                                    component="form"
                                                                    sx={{
                                                                        "& > :not(style)": {
                                                                            width: isXs ? "95%" : "100%",
                                                                        },
                                                                    }}
                                                                    noValidate
                                                                    autoComplete="off"
                                                                >
                                                                    <TextField
                                                                        {...register("pinCode")}
                                                                        type="number"
                                                                        name="pinCode"
                                                                        label="Pin Code"
                                                                        placeholder="Enter your Pin Code"

                                                                        sx={{ mixLength: 6, maxLength: 6 }}

                                                                        inputProps={{
                                                                            style: {

                                                                                height: "7px",
                                                                                fontSize: isXs ? "12px" : "16px",
                                                                            },
                                                                        }}
                                                                        InputLabelProps={{ sx: { color: "black" } }}
                                                                        color="grey"
                                                                        focused
                                                                        value={isPincode}
                                                                        onChange={(e) => {
                                                                            if (e.target.value.length > 6) {
                                                                                return e.target.value = ""
                                                                            }
                                                                            e.target.value.length == 6 && handlePinCodeCheck(e);
                                                                            handlePincode(e)
                                                                        }} />
                                                                    <p style={{ fontSize: "12px", color: "red", marginRight: isXs ? '12%' : '' }}>
                                                                        {errors.pinCode?.message}
                                                                    </p>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>

                                                        {/* <Grid container sx={{ marginTop: '5%' }}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                                <Box
                                                                    component="form"
                                                                    sx={{
                                                                        '& > :not(style)': { width: '100%' },
                                                                    }}
                                                                    noValidate
                                                                    autoComplete="off"
                                                                >
                                                                    <TextField label="Course/Class/Board" placeholder='Enter the main category you teach; more could be added later' inputProps={{ style: { height: '7px', fontSize: isXs ? '12px' : '16px' } }} InputLabelProps={{ sx: { color: 'black' } }} color='grey' focused />
                                                                </Box>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container sx={{ marginTop: '5%' }}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                                <Box
                                                                    component="form"
                                                                    sx={{
                                                                        '& > :not(style)': { width: '100%' },
                                                                    }}
                                                                    noValidate
                                                                    autoComplete="off"
                                                                >
                                                                    <TextField label="Enter Pin code or Locality" placeholder='Enter Pin code or Locality (Eg: 302020 or Mansarovar)' inputProps={{ style: { height: '7px', fontSize: isXs ? '12px' : '16px' } }} InputLabelProps={{ sx: { color: 'black' } }} color='grey' focused />
                                                                </Box>
                                                            </Grid>
                                                        </Grid> */}
                                                        <Box
                                                            className="mt-2"
                                                            style={{ width: "100%", textAlign: "center" }}
                                                        >
                                                            <button
                                                                className="btn btn-primary py-2 w-100 border-0"
                                                                style={{
                                                                    fontSize: "14px",
                                                                    backgroundColor: "#FFDB7E",
                                                                    color: "#866D2C",
                                                                    fontWeight: "600",
                                                                    borderRadius: "0",
                                                                }}
                                                            >
                                                                Signup
                                                            </button>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </form>
                                            <Box
                                                className="d-flex flex-row justify-content-between w-full my-3"
                                                style={{ position: "relative" }}
                                            >
                                                <Box
                                                    className="flex-grow-1 text-center"
                                                    style={{ fontSize: "12px", fontWeight: "900" }}
                                                >
                                                    <small className="block">
                                                        <Box>
                                                            By Signing Up, you agree to our{" "}
                                                            <Link to="/terms-conditions" style={{ color: "#2E66FB" }}>Terms of use</Link>
                                                            {" "}
                                                            &{" "}
                                                            <Link to="/privacy-policy" style={{ color: "#2E66FB" }}>Privacy Policy</Link>

                                                        </Box>
                                                        <span>Already have an account?</span>
                                                        {/* <a href="#" style={{ color: "#AF6031" }}>  /tutor-signin
                                                            Login Now!
                                                        </a> */}
                                                        <Link to="/signin-teacher" style={{ color: "#AF6031" }}>Login Now!</Link>
                                                    </small>
                                                </Box>
                                                <Box>
                                                    <img
                                                        src={bulb}
                                                        style={{
                                                            position: "absolute",
                                                            right: "2%",
                                                           
                                                            display: isXs ? "" : "none",
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default SignupTeacher;
